
export default {
  props: {
    id: {
      default: '',
      type: String,
    },
    infobox: {
      default: '',
      type: String,
    },
    center: {
      default: '',
      type: String,
    },
    squeeze: {
      default: 'false',
      type: String,
    },
    classes: {
      default: '',
      type: String,
    },
  },
  render() {
    const slot = this.$slots.default
    //  return slot.text.replace(/,/g, "")
    return slot
  },

}
